var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.list.length > 0)?_c('div',_vm._l((_vm.list),function(item,idx){return _c('div',{key:item.uid || idx,staticClass:"d-flex mb-4"},[_c('div',{staticClass:"col pl-0 fixlabel-price-formula"},[_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('price_setting.label_variable'))+" "),_c('sup',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-form-model-item',{staticClass:"mb-0",attrs:{"prop":'formulaVariables.' + idx + '.id',"rules":[{
          required: true,
          message: _vm.$t('price_setting.error_variable_name'),
        }]}},[_c('a-select',{staticClass:"h-48px",attrs:{"show-search":"","size":"large","option-filter-prop":"children","filter-option":_vm.filterOption,"not-found-content":_vm.loadFormula ? undefined : _vm.$t('utils.not_found')},on:{"dropdownVisibleChange":(open) => _vm.$emit('dropdownVisibleChange', open),"change":(val) => _vm.$emit('changeVarName', val, idx)},model:{value:(item.id),callback:function ($$v) {_vm.$set(item, "id", $$v)},expression:"item.id"}},[(_vm.loadFormula)?_c('a-spin',{attrs:{"slot":"notFoundContent","size":"small"},slot:"notFoundContent"}):_vm._e(),_vm._l(((_vm.formulas || [])),function(val){return _c('a-select-option',{key:val.id,attrs:{"value":val.id,"disabled":_vm.formulaUse && _vm.formulaUse.includes(val.id)}},[_vm._v(" "+_vm._s(val.symbol)+" ")])})],2)],1)],1),_c('div',{staticClass:"col pr-0"},[_vm._m(0,true),_c('div',{staticClass:"d-flex"},[_c('a-form-model-item',{staticClass:"mb-0 w-100 mr-3",attrs:{"prop":'formulaVariables.' + idx + '.value',"rules":[{
            required: true,
            message: _vm.$t('price_setting.error_value_empty'),
          }]}},[_c('a-input-number',{staticClass:"w-100 h-48px",attrs:{"size":"large","min":0},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1),_c('a-button',{staticClass:"h-48px",attrs:{"size":"large","icon":"delete","tabindex":"-1"},on:{"click":function($event){return _vm.$emit('onRemoveItem', item, idx)}}})],1)])])}),0):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"mb-1"},[_vm._v(" Value "),_c('sup',{staticClass:"text-danger"},[_vm._v("*")])])
}]

export { render, staticRenderFns }