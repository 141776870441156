<template>
  <div v-if="list.length > 0">
    <div
      v-for="(item, idx) in list"
      :key="item.uid || idx"
      class="d-flex mb-4"
    >
      <div class="col pl-0 fixlabel-price-formula">
        <label class="mb-1">
          {{ $t('price_setting.label_variable') }} <sup class="text-danger">*</sup>
        </label>
        <a-form-model-item
          :prop="'formulaVariables.' + idx + '.id'"
          class="mb-0"
          :rules="[{
            required: true,
            message: $t('price_setting.error_variable_name'),
          }]"
        >
          <a-select
            v-model="item.id"
            show-search
            size="large"
            class="h-48px"
            option-filter-prop="children"
            :filter-option="filterOption"
            :not-found-content="loadFormula ? undefined : $t('utils.not_found')"
            @dropdownVisibleChange="(open) => $emit('dropdownVisibleChange', open)"
            @change="(val) => $emit('changeVarName', val, idx)"
          >
            <a-spin v-if="loadFormula" slot="notFoundContent" size="small" />
            <a-select-option
              v-for="val in (formulas || [])"
              :key="val.id"
              :value="val.id"
              :disabled="formulaUse && formulaUse.includes(val.id)"
            >
              {{ val.symbol }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </div>
      <div class="col pr-0">
        <label class="mb-1">
          Value <sup class="text-danger">*</sup>
        </label>
        <div class="d-flex">
          <a-form-model-item
            :prop="'formulaVariables.' + idx + '.value'"
            class="mb-0 w-100 mr-3"
            :rules="[{
              required: true,
              message: $t('price_setting.error_value_empty'),
            }]"
          >
            <a-input-number
              v-model="item.value"
              class="w-100 h-48px"
              size="large"
              :min="0"
            />
          </a-form-model-item>
          <a-button
            class="h-48px"
            size="large"
            icon="delete"
            tabindex="-1"
            @click="$emit('onRemoveItem', item, idx)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['list', 'loadFormula', 'formulas', 'formulaUse'],
  emits: ['onRemoveItem', 'dropdownVisibleChange', 'changeVarName'],
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
  },
}
</script>

<style lang="scss">
.fixlabel-price-formula .ant-form-item-children {
  display: block;
  height: 48px !important;
}
</style>
