<template>
  <div>
    <h6 class="font-weight-bold m-0 py-2 position-sticky bg-white headVaribleGroup">
      {{ title }}
    </h6>
    <template v-if="list.length > 0">
      <CardVariable
        v-for="(item, index) in list"
        :key="item.id + index"
        :symbol="item.symbol"
        :value="item.categories !== 'PRODUCT' ? item.value || item.default_value : false"
        :name="item.name"
        @onClickItem="item.symbol && $emit('onClickItem', item)"
      />
    </template>
    <a-empty
      v-else
      class="ml-0 mr-1 p-2 border rounded"
    />
  </div>
</template>

<script>
import CardVariable from './CardVariable.vue'
export default {
  components: {
    CardVariable,
  },
  props: ['title', 'list'],
  emits: ['onClickItem'],
}
</script>

<style lang="scss" scoped>
.headVaribleGroup {
  top: -1px;
  z-index: 1;
}
</style>
