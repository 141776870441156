<template>
  <div class="card pt-2 mb-2 w-100 shadow-sm">
    <div class="d-flex">
      <div :class="typeof value === 'number' ? 'col-7' : 'col'">
        <p class="pbz-font caption-regular text-muted mb-0">
          {{ $t('price_setting.label_variable') }}
        </p>
        <p class="pbz-font body-sm-regular mb-0">
          {{ symbol }}
        </p>
      </div>
      <div
        v-if="typeof value === 'number'"
        class="col-5"
      >
        <p class="pbz-font caption-regular text-muted mb-0">
          Value
        </p>
        <p class="pbz-font body-sm-regular mb-0">
          {{ value }}
        </p>
      </div>
    </div>
    <p class="text-muted pbz-font overline-medium pl-3 mb-2">
      {{ name }}
    </p>
    <a-button
      class="pbz-font button-sm-bold text-center w-100 border-bottom-0 border-left-0 border-right-0 bottom"
      @click="$emit('onClickItem')"
    >
      <a-icon type="plus" />{{ $t('utils.use') }} {{ $t('utils.variable') }}
    </a-button>
  </div>
</template>

<script>
export default {
  props: ['symbol', 'value', 'name'],
  emits: ['onClickItem'],
}
</script>

<style lang="scss" scoped>
.bottom {
  color: #0559cb;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.bottom:hover {
  color: #fff;
  background-color: #04449a;
}
</style>
