<template>
  <a-modal
    :visible="visible"
    :footer="false"
    :closable="false"
    :width="520"
    :height="440"
    :dialog-style="{ top: '15px' }"
    @cancel="$emit('cancel')"
  >
    <a-input
      size="large"
      allow-clear
      :placeholder="$t('utils.search') + ' ' + searchPlaceholder"
      @change="onFind"
    >
      <a-icon slot="prefix" type="search" />
    </a-input>

    <div>
      <div class="d-flex mt-3 user-select-none">
        <a-button
          v-if="formula && formula.length > 0"
          shape="circle"
          icon="arrow-left"
          @click="$emit('onClickBack')"
        />
        <div class="ml-auto">
          <a-button
            shape="circle"
            icon="plus"
            @click="$emit('onClickItem', '+')"
          />{{ ' ' }}
          <a-button
            shape="circle"
            icon="minus"
            @click="$emit('onClickItem', '-')"
          />{{ ' ' }}
          <a-button
            shape="circle"
            icon="close"
            @click="$emit('onClickItem', '*')"
          />{{ ' ' }}
          <a-button
            class="font-weight-bold"
            shape="circle"
            @click="$emit('onClickItem', '/')"
          >
            ∕
          </a-button>{{ ' ' }}
          <a-button
            class="text-monospace"
            shape="circle"
            @click="$emit('onClickBracket', '(')"
          >
            <b class="d-inline-block">(</b>
          </a-button>{{ ' ' }}
          <a-button
            class="text-monospace"
            shape="circle"
            @click="$emit('onClickBracket', ')')"
          >
            <b class="d-inline-block">)</b>
          </a-button>{{ ' ' }}
          <a-button
            shape="circle"
            @click="$emit('onClickConstant')"
          >
            <b class="d-inline-block">123</b>
          </a-button>
        </div>
      </div>
      <div
        v-if="(formula && formula.length > 0) || constant !== null"
        style="min-height:56px"
        class="ant-alert ant-alert-info ant-alert-with-description ant-alert-no-icon mt-3 font-weight-bold text-monospace d-flex align-items-center"
      >
        <!-- :maxLength="12" -->
        <!-- :max="10" -->
        {{ parseFormula(formula) }}<a-input-number
          v-if="constant !== null"
          class="nostep"
          size="small"
          auto-focus
          :style="{ width: inputConstWidth }"
          :value="constant"
          @change="(val) => $emit('onChangeConstant', val)"
          @blur.stop="$emit('onBlurConstant')"
          @keydown.stop.enter="e => e.target.blur()"
          @keyup.stop="e => $emit('onKeyupConstant', e)"
        />
      </div>

      <div class="d-flex my-3">
        <!-- NOTES: Formula Variabel From form Formula Variable / +Tambah Variable -->
        <a-button
          v-for="(item, idx) in [$t('utils.all'), $t('sideBar.global_variable'), $t('sideBar.formula_variable'), $t('sideBar.product_variable')]"
          :key="'t' + idx"
          shape="round"
          size="small"
          class="px-2 flex-grow-1 mr-1"
          :type="tab === idx ? 'primary':'default'"
          @click="onTab(idx, item)"
        >
          {{ item }}
        </a-button>
      </div>

      <div
        class="my-2 pr-2"
        style="height: 420px; overflow-x: hidden"
      >
        <template v-if="searchValue.length > 0">
          <template v-if="searchResult.length > 0">
            <CardVariable
              v-for="(item, index) in searchResult"
              :key="item.id || index"
              :symbol="item.symbol"
              :value="item.value || item.default_value"
              :name="item.name"
              @onClickItem="$emit('onClickItem', item)"
            />
          </template>
          <a-empty
            v-else
            class="ml-0 mr-1 p-2 border rounded"
          />
        </template>
        <template v-else>
          <ItemContent
            v-if="tab === 0 || tab === 1"
            class="mb-4"
            :title="$t('sideBar.global_variable')"
            :list="variablesGlobal"
            @onClickItem="(item) => $emit('onClickItem', item)"
          />

          <ItemContent
            v-if="tab === 0 || tab === 2"
            class="mb-4"
            :title="$t('sideBar.formula_variable')"
            :list="variablesFormula"
            @onClickItem="(item) => $emit('onClickItem', item)"
          />

          <ItemContent
            v-if="tab === 0 || tab === 3"
            class="mb-4"
            :title="$t('sideBar.product_variable')"
            :list="variablesProduct"
            @onClickItem="(item) => $emit('onClickItem', item)"
          />
        </template>
      </div>

      <div class="text-right mt-3">
        <a-button
          class="mr-1"
          @click="$emit('cancel')"
        >
          {{ $t('utils.cancel') }}
        </a-button>
        <a-button
          type="primary"
          @click="$emit('onClickApply', formula)"
        >
          {{ $t('utils.apply') }}
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import CardVariable from './CardVariable.vue'
import ItemContent from './ItemContent.vue'
export default {
  components: {
    CardVariable,
    ItemContent,
  },
  props: [
    'visible',
    'variablesGlobal',
    'variablesFormula',
    'variablesProduct',
    'formula',
    'constant',
    'inputConstWidth',
  ],
  emits: [
    'cancel',
    'onClickApply',
    'onClickBracket',
    'onClickItem',
    'onClickConstant',
    'onChangeConstant',
    'onBlurConstant',
    'onKeyupConstant',
    'onClickBack',
  ],
  data() {
    return {
      tab: 0,
      searchPlaceholder: '',
      searchValue: '',
      searchResult: [],
    }
  },
  methods: {
    parseFormula(formula) {
      return formula && formula.length > 0 ? formula.map(v => typeof v === 'object' ? (v.symbol ? `{${v.symbol}}` : v.constant) : v).join('') : ''
    },
    onTab(idx, item) {
      this.tab = idx
      this.searchPlaceholder = idx === 0 ? '' : item
    },
    onFind(e) {
      const val = e.target.value.trim()
      if (val.length > 0) {
        const { tab, variablesGlobal, variablesFormula, variablesProduct } = this
        const valLower = val.toLowerCase()
        let toSearch = []
        switch (tab) {
          case 1: // Variable Global
            toSearch = variablesGlobal
            break
          case 2: // Variable Formula
            toSearch = variablesFormula
            break
          case 3: // Variable Product
            toSearch = variablesProduct
            break
          default: // All
            toSearch = [
              ...variablesGlobal,
              ...variablesFormula,
              ...variablesProduct,
            ]
            break
        }
        this.searchResult = toSearch.filter(
          (f) =>
            (f.name || '').toLowerCase().includes(valLower) ||
            (f.symbol || '').toLowerCase().includes(valLower),
        )
      } else {
        this.searchResult = []
      }
      this.searchValue = val
    },
  },
}
</script>
